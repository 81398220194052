import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import { Auth0 } from 'utils/Constants'

import styles from 'assets/jss/material-dashboard-pro-react/views/User/createStyle.js'

function UserConsentView1 (props) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const [agree, setAgree] = React.useState(false)
  const [auth0state, setAuth0State] = React.useState('')

  //Component did mount
  useEffect(() => {
    const search = props.location.search
    const params = new URLSearchParams(search)
    setAuth0State(params.get('state'))
  }, [props])

  const btnHandler = async () => {
    console.log('New value for agree btnHandler: ' + agree)
    window.location.href = `https://${
      Auth0.Domain
    }/continue?state=${auth0state}&confirm=${agree ? 'yes' : 'no'}`
  }

  const handleChange = event => {
    setAgree(event.target.checked)
    console.log('New value for agree checkbox handler: ' + agree)
  }

  return (
    <div>
      <div className="container">
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ padding: '0% 20% 0px' }}>
              <CardHeader color="info" icon></CardHeader>
              <CardBody>
                <div className="jumbotron">
                  <div
                    style={{ justifyContent: 'center', textAlign: 'center' }}
                  >
                    <h1>Relish Inc Terms & Conditions </h1>
                    <br></br>
                    <br></br>
                  </div>
                  {/* <h2>Privacy Notice</h2> */}
                  <div style={{ justifyContent: 'center', textAlign: 'left' }}>
                    <Card
                      style={{
                        maxWidth: 1000,
                        alignContent: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <CardHeader color="primary">
                        <h3 style={{ color: 'white' }}>Privacy Notice</h3>
                      </CardHeader>
                      <CardBody>
                        <p>
                          Relish Inc values your privacy when you access our
                          website and applications. Note that we do not require
                          your personal data such as your name, phone number,
                          email address. Most of our clients who use our
                          applications, however, sometimes elect to store
                          contact data associated with users. As such, in your
                          use of these applications, contact data may be
                          transferred outside of the country or jurisdiction in
                          which you are located.
                        </p>
                        <br></br>
                        <p>
                          Relish Inc has obligations as a processor of your
                          contact data as defined in agreement between your
                          company or organization and Relish Inc. This agreement
                          governs the license to use this cloud-based
                          application. The{' '}
                          <a href="https://relishiq.com/privacy-policy/">
                            Relish Privacy Policy
                          </a>{' '}
                          documents Relish’s practices at a high level.
                        </p>
                      </CardBody>
                    </Card>
                  </div>

                  {/* <h2>Cookie Usage Statement</h2> */}
                  <div style={{ justifyContent: 'center', textAlign: 'left' }}>
                    <Card
                      style={{
                        maxWidth: 1000,
                        alignContent: 'center',
                        justifyContent: 'center',
                        marginTop: '5%'
                      }}
                    >
                      <CardHeader color="primary">
                        <h3 style={{ color: 'white' }}>
                          Cookie Usage Statement
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <p>
                          Relish Inc’s website and applications use cookies to
                          store information on your computer for some or all of
                          the following reasons:
                        </p>
                        <ul>
                          <li>To make our site work</li>
                          <li>To help us improve the user experience</li>
                          <li>To provide added security</li>
                        </ul>
                        <p>
                          By using the site from the device you are using, you
                          consent to the placement of these cookies on that
                          device. Read more about our use of cookies in the{' '}
                          <a href="https://relishiq.com/privacy-policy/">
                            Relish Privacy Policy
                          </a>
                          .
                        </p>
                      </CardBody>
                    </Card>
                  </div>
                  {/* <p>
                                            Relish LLC’s website and applications use cookies to store information on your computer for some or all of the following reasons:
                                        </p>
                                        <ul>
                                            <li>To make our site work</li>
                                            <li>To help us improve the user experience</li>
                                            <li>To provide added security</li>
                                        </ul>   
                                        <p>By using the site from the device you are using, you consent to the placement of these cookies on that device. Read more about our use of cookies in the Relish Privacy Policy.</p> */}
                  <form
                    onSubmit={btnHandler}
                    className={classes.justifyContentCenter}
                  >
                    <div className="checkbox" style={{ marginTop: '2%' }}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={agree}
                              onChange={handleChange}
                              name="checkedB"
                              color="default"
                            />
                          }
                          label="I have read and I acknowledge the Cookie Usage Statement. Further, I acknowledge that my personal data will be processed as described in the Relish Privacy Policy."
                        />
                      </FormGroup>
                    </div>
                    <div
                      style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginTop: '2%'
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={!agree}
                        size="large"
                        onClick={btnHandler}
                      >
                        Agree
                      </Button>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

export default UserConsentView1
