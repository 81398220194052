import isDate from 'validator/es/lib/isDate'
import isEmail from 'validator/es/lib/isEmail'
import isURL from 'validator/es/lib/isURL'

const passwordValidator = require('password-validator')

class SchemaValidator {
  // JSON schema

  constructor(schema) {
    this.schema = schema
  }

  /**
   * Validates an object according to the schema provided in the constructor
   */
  validate(data) {
    let result = {
      isValid: true,
      messages: {}
    }
    try {
      let property
      for (property in this.schema) {
        let validationResult = this.validateProperty(property, data)
        if (!validationResult.isValid) {
          result.isValid = result.isValid && validationResult.isValid
          result.messages[property] = validationResult.messages
        }
      }
    } catch (error) {
      console.log(error)
    }
    console.log(`Validate: ${JSON.stringify(result)}`)
    return result
  }

  validateProperty(property, data) {
    let result = {
      isValid: true,
      messages: []
    }
    try {
      let propValue = this.schema[property]

      let validationResult
      switch (propValue.type) {
        case 'string':
          validationResult = this.validateString(property, data)
          break
        case 'integer':
          validationResult = this.validateInteger(property, data)
          break
        case 'decimal':
          validationResult = this.validateDecimal(property, data)
          break
        case 'boolean':
          validationResult = this.validateBoolean(property, data)
          break
        case 'array':
          validationResult = this.validateArray(property, data)
          break
        default:
          break
      }
      if (validationResult) {
        result.isValid = validationResult.isValid
        result.messages = validationResult.messages
      }
    } catch (error) {
      console.log(error)
    }
    return result
  }

  /**
   * Validates an string property according to the schema provided in the constructor
   */
  validateString(property, data) {
    let result = {
      isValid: true,
      messages: []
    }
    try {
      let schema = this.schema[property]

      // Validate custom functions
      let validationResult = this.validateCustomRules(property, data)
      result.isValid = result.isValid && validationResult.isValid
      result.messages = validationResult.messages

      // Validate required
      if (!data[property] && data[property] !== 0) {
        if (schema.required) {
          result.isValid = false
          result.messages.push(`The field ${schema.displayName} is required`)
        }
        return result
      }

      // Validate data type
      if (!(typeof data[property] === 'string' || data[property] instanceof String)) {
        result.isValid = false
        result.messages.push(`'The field ${schema.displayName} should be a String`)

        return result
      }

      // Validate length
      if (schema.length) {
        if (schema.length.min) {
          if (data[property].length < schema.length.min) {
            result.isValid = false
            result.messages.push(
              `'The field ${schema.displayName} must have a minimum length of ${schema.length.min}`
            )

            return result
          }
        }

        if (schema.length.max) {
          if (data[property].length > schema.length.max) {
            result.isValid = false
            result.messages.push(
              `'The field ${schema.displayName} must have a maximum length of ${schema.length.max}`
            )

            return result
          }
        }
      }

      // Validate email
      if (schema.isEmail) {
        //const regexEmail = /\S+@\S+\.\S+/
        if (!isEmail(data[property])) {
          result.isValid = false
          result.messages.push(`'The field ${schema.displayName} must be a valid email`)
        }
      }

      // Validate url
      if (schema.isURL) {
        //const regexEmail = /\S+@\S+\.\S+/
        if (!isURL(data[property])) {
          result.isValid = false
          result.messages.push(`'The field ${schema.displayName} must be a valid URL`)
        }
      }

      // Validate Path
      if (schema.isPath) {
        const regex = RegExp(/^([\w-_()@.\s]+(\/)?)+$/)
        if (!regex.test(data[property])) {
          result.isValid = false
          result.messages.push(`'The field ${schema.displayName} must be a valid Path`)
        }
      }

      // Validate password
      if (schema.isPassword) {
        let passwordSchema = new passwordValidator()

        // Add properties to it
        passwordSchema
          .is()
          .min(12) // Minimum length 8
          .has()
          .uppercase() // Must have uppercase letters
          .has()
          .lowercase() // Must have lowercase letters
          .has()
          .digits() // Must have digits

        if (!passwordSchema.validate(data[property])) {
          result.isValid = false
          result.messages.push(`The field ${schema.displayName} es una weak password`)
        }
      }

      if (schema.isAlphaNumeric) {
        var letterNumber = /^[0-9a-zA-Z]+$/
        if (!data[property].match(letterNumber)) {
          result.isValid = false
          result.messages.push(`'The field ${schema.displayName} must be alpha numeric`)
        }
      }

      if (schema.isDate) {
        if (!isDate(data[property], { format: 'MM/DD/YYYY' })) {
          result.isValid = false
          result.messages.push(`'The field ${schema.displayName} must be a valid date`)
        }
      }
    } catch (error) {
      console.error(error)
    }
    return result
  }

  /**
   * Validates an integer property according to the schema provided in the constructor
   */
  validateInteger(property, data) {
    let result = {
      isValid: true,
      messages: []
    }
    try {
      let schema = this.schema[property]

      // Validate custom functions
      let validationResult = this.validateCustomRules(property, data)
      result.isValid = result.isValid && validationResult.isValid
      result.messages = validationResult.messages

      // Validate required
      if (!data[property] && data[property] !== 0) {
        if (schema.required) {
          result.isValid = false
          result.messages.push(`The field ${schema.displayName} is required`)
        }
        return result
      }

      // Validate data type
      if (data[property] !== parseInt(data[property], 10)) {
        result.isValid = false
        result.messages.push(`'The field ${schema.displayName} must be and integer`)

        return result
      }

      // Validate length
      if (schema.min) {
        if (data[property] < schema.min) {
          result.isValid = false
          result.messages.push(
            `'The field ${schema.displayName} must not be less than ${schema.min}`
          )

          return result
        }
      }

      if (schema.max) {
        if (data[property] > schema.max) {
          result.isValid = false
          result.messages.push(
            `'The field ${schema.displayName} must not be greater than ${schema.max}`
          )

          return result
        }
      }
    } catch (error) {
      console.error(error)
    }
    return result
  }

  /**
   * Validates an decimal property according to the schema provided in the constructor
   */
  validateDecimal(property, data) {
    let result = {
      isValid: true,
      messages: []
    }
    try {
      let schema = this.schema[property]

      // Validate custom functions
      let validationResult = this.validateCustomRules(property, data)
      result.isValid = result.isValid && validationResult.isValid
      result.messages = validationResult.messages

      // Validate required
      if (!data[property] && data[property] !== 0) {
        if (schema.required) {
          result.isValid = false
          result.messages.push(`The field ${schema.displayName} is required`)
        }
        return result
      }

      // Validate data type
      if (typeof data[property] !== 'number') {
        result.isValid = false
        result.messages.push(`'The field ${schema.displayName} must be a number`)

        return result
      }

      // Validate length
      if (schema.min) {
        if (data[property] < schema.min) {
          result.isValid = false
          result.messages.push(
            `'The field ${schema.displayName} must not be less than ${schema.min}`
          )

          return result
        }
      }

      if (schema.max) {
        if (data[property] > schema.max) {
          result.isValid = false
          result.messages.push(
            `'The field ${schema.displayName} must not be greater than ${schema.max}`
          )

          return result
        }
      }
    } catch (error) {
      console.error(error)
    }
    return result
  }

  /**
   * Validates an boolean property according to the schema provided in the constructor
   */
  validateBoolean(property, data) {
    let result = {
      isValid: true,
      messages: []
    }
    try {
      let schema = this.schema[property]

      // Validate custom functions
      let validationResult = this.validateCustomRules(property, data)
      result.isValid = result.isValid && validationResult.isValid
      result.messages = validationResult.messages

      // Validate required
      if (data[property] === null || data[property] === undefined) {
        if (schema.required) {
          result.isValid = false
          result.messages.push(`The field ${schema.displayName} is required`)
        }
        return result
      }

      // Validate data type
      if (typeof data[property] !== 'boolean') {
        result.isValid = false
        result.messages.push(`'The field ${schema.displayName} must be a boolean`)

        return result
      }
    } catch (error) {
      console.error(error)
    }
    return result
  }

  /**
   * Validates an array property according to the schema provided in the constructor
   */
  validateArray(property, data) {
    let result = {
      isValid: true,
      messages: []
    }
    try {
      let schema = this.schema[property]

      // Validate custom functions
      let validationResult = this.validateCustomRules(property, data)
      result.isValid = result.isValid && validationResult.isValid
      result.messages = validationResult.messages

      // Validate required
      if (data[property] === null || data[property] === undefined) {
        if (schema.required) {
          result.isValid = false
          result.messages.push(`The field ${schema.displayName} is required`)
        }
        return result
      }

      // Validate data type
      if (!Array.isArray(data[property])) {
        result.isValid = false
        result.messages.push(`'The field ${schema.displayName} must be an array`)

        return result
      }

      // Validate data type
      if (!Array.isArray(data[property])) {
        result.isValid = false
        result.messages.push(`'The field ${schema.displayName} must be an array`)

        return result
      }

      // Validate length
      if (schema.length) {
        if (schema.length.min) {
          if (data[property].length < schema.length.min) {
            result.isValid = false
            result.messages.push(
              `'The field ${schema.displayName} must have a minimum length of ${schema.length.min}`
            )

            return result
          }
        }

        if (schema.length.max) {
          if (data[property].length > schema.length.max) {
            result.isValid = false
            result.messages.push(
              `'The field ${schema.displayName} must have a maximum length of ${schema.length.max}`
            )

            return result
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
    return result
  }

  /**
   * Validates the custom rules for a property
   */
  validateCustomRules(property, data) {
    let result = {
      isValid: true,
      messages: []
    }
    try {
      let schema = this.schema[property]
      // Validate required
      if (schema.customRules) {
        schema.customRules.forEach(customRule => {
          let validation = customRule.rule(data)
          if (!validation) {
            result.isValid = false
            result.messages.push(customRule.message)
          }
        })
      }
    } catch (error) {
      console.error(error)
    }
    return result
  }
}

export default SchemaValidator
