import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import { get } from 'lodash'
import React from 'react'
import { findBestMatch } from 'string-similarity'
import {
  chargesTypesByProcess,
  ColumnsOrderChargesTaxesStandard,
  ColumnsOrderLineItemsStandard,
  specialPOLines,
  taxesTypesByProcess
} from 'utils/Constants'
import { maxDecimalAndSummation } from 'utils/functions'
import { validateInputV2 } from 'utils/validator'
import { v4 as uuidv4 } from 'uuid'
import { errorTypes } from './useHeader'
import { currencyFormatter, getStandardRule, getValidationRule, isTaxLineEnable } from './utils'

const actionTypes = {
  DELETE_LINES: 'DELETE_LINES',
  ADD_LINE: 'ADD_LINE',
  EDIT: 'EDIT',
  EDIT_FIELDS: 'EDIT_FIELDS',
  CLONE_LINE: 'CLONE_LINE',
  SPLIT_LINE: 'SPLIT_LINE',
  MERGE: 'MERGE',
  CONSOLIDATE: 'CONSOLIDATE',
  RESET: 'RESET',
  SET_SELECTED_LINES: 'SET_SELECTED_LINES',
  SET_DATA: 'SET_DATA',
  UPDATE_PAGINATION: 'UPDATE_PAGINATION',
  SET_PO_LINES: 'SET_PO_LINES',
  UPDATE_ACCOUNTING: 'UPDATE_ACCOUNTING',
  VALIDATE_ACCOUNT_SEGMENTS: 'VALIDATE_ACCOUNT_SEGMENTS',
  UPDATE_LINES: 'UPDATE_LINES',
  UPDATE_LINE_TAXES: 'UPDATE_LINE_TAXES'
}

function generateConditionalMerge(key, value, isMerge) {
  return isMerge
    ? value || key === 'id'
    : value ||
        key === 'id' ||
        key === 'unitPrice' ||
        key === 'price' ||
        key === 'quantity' ||
        key === 'total'
}

function getLinesToEliminate(selectedLine, state) {
  const { unselected, selected } = selectedLine
  let linesToDelete = []
  let rowsSelectedTemp = [...state.lines]
  if (typeof selected === 'boolean') {
    if (!selected) {
      return linesToDelete
    }
    const unselectedKeys = Object.keys(unselected ?? {})
    const rowsUpdated = rowsSelectedTemp.filter(x => !unselectedKeys.includes(x.id))
    // eslint-disable-next-line
    rowsSelectedTemp = rowsUpdated.reduce((acc, curr) => ((acc[curr.id] = curr), acc), {})

    linesToDelete = Object.values(rowsSelectedTemp)
  } else {
    linesToDelete = Object.values(selected)
  }

  return linesToDelete
}

function getColor(typeMessage) {
  if (typeMessage === 'critical') {
    return 'red'
  }
  return '#f3bc07'
}

export function getColumns(params) {
  const {
    lines,
    poInformation,
    classes,
    setCreateError,
    requiredFields,
    onChangePoLineItem,
    accountSegmentsEnabled,
    showAccountSegments,
    showAddressesModal,
    showTaxes
  } = params

  const columns = []
  const columnPos = {}

  const lineItemsFields = requiredFields.filter(x => x.level === 'lineItem')

  const lineItemsFieldsSorted = lineItemsFields
    .map(x => ({
      ...x,
      pos: ColumnsOrderLineItemsStandard[x.standardField]?.pos ?? lineItemsFields.length
    }))
    .sort(function sortElements(a, b) {
      return a.pos - b.pos
    })

  columns.push({
    header: 'id',
    name: 'id',
    defaultFlex: 1,
    minWidth: 100,
    editable: false,
    defaultVisible: false
  })

  // eslint-disable-next-line
  for (const field of lineItemsFieldsSorted) {
    if (
      !ColumnsOrderLineItemsStandard[field.standardField] ||
      (ColumnsOrderLineItemsStandard[field.standardField] &&
        ColumnsOrderLineItemsStandard[field.standardField].section !== 'column')
    ) {
      continue
    }

    if (field.standardField === ColumnsOrderLineItemsStandard.poLineNumber.type) {
      const options = []
      options.push(
        <option key={'none'} value="">
          No Po Match
        </option>
      )
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(poInformation ?? {})) {
        options.push(
          <option key={key} value={key}>
            {value?.summaryLine ?? ''}
          </option>
        )
      }

      columns.push({
        name: field.standardField,
        header: field.displayName,
        defaultFlex: 1,
        editable: false,
        defaultVisible: field.isVisible,
        render: ({ value, data }) => {
          const fieldRender = (
            <select
              name="po"
              value={value}
              id="po"
              className={classes.dropdown}
              onChange={e => onChangePoLineItem(data.id, e.target.value)}
            >
              {options.length > 0 ? options : null}
            </select>
          )

          const lineErrors = lines.errors.find(x => x.id === data.id)
          const fieldError = lineErrors?.errors?.find(x => x.field === field.standardField)

          if (!fieldError) {
            return fieldRender
          }

          let typeMessage = ''
          const criticalType = fieldError.details.find(x => x.criticalityType === 'critical')

          if (criticalType) {
            typeMessage = 'critical'
          }

          const colorInput = getColor(typeMessage)

          const icon = criticalType ? (
            <ErrorIcon style={{ color: 'red' }} />
          ) : (
            <WarningIcon style={{ color: '#f3bc07' }} />
          )

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              {fieldRender}

              <div
                style={{
                  color: colorInput,
                  textAlign: 'right'
                }}
              >
                <IconButton
                  size="small"
                  component="span"
                  onClick={() =>
                    setCreateError({
                      isOpen: true,
                      message: criticalType.message,
                      color: typeMessage === 'warning' ? 'warning' : 'danger'
                    })
                  }
                >
                  {icon}
                </IconButton>
              </div>
            </div>
          )
        }
      })

      continue
    }

    columns.push({
      name: field.standardField,
      header: field.displayName,
      defaultFlex: 1,
      minWidth: 100,
      defaultVisible: field.isVisible,
      render: ({ value, data }) => {
        const lineErrors = lines.errors.find(x => x.id === data.id)

        const fieldError = lineErrors?.errors?.find(x => x.field === field.standardField)

        if (!fieldError) {
          return value
        }

        let typeMessage = ''
        const criticalType = fieldError.details.find(x => x.criticalityType === 'critical')

        if (criticalType) {
          typeMessage = 'critical'
        }

        const colorInput = getColor(typeMessage)

        const icon = criticalType ? (
          <ErrorIcon style={{ color: 'red' }} />
        ) : (
          <WarningIcon style={{ color: '#f3bc07' }} />
        )

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div style={{ color: colorInput }}>{value}</div>
            <div
              style={{
                color: colorInput,
                textAlign: 'right'
              }}
            >
              <IconButton
                size="small"
                component="span"
                onClick={() =>
                  setCreateError({
                    isOpen: true,
                    message: criticalType.message,
                    color: typeMessage === 'warning' ? 'warning' : 'danger'
                  })
                }
              >
                {icon}
              </IconButton>
            </div>
          </div>
        )
      }
    })
  }

  if (isTaxLineEnable()) {
    columns.push({
      name: 'tax',
      header: 'Tax',
      defaultFlex: 1,
      minWidth: 100,
      defaultVisible: true,
      editable: false,
      render: ({ data }) => {
        const total =
          lines.taxAndCharges?.[data.id]?.taxes?.reduce(
            (acc: number, obj) => acc + Number(obj?.amount ?? 0),
            0
          ) ?? 0

        return (
          <div style={{ display: 'flex' }}>
            <Button
              variant="outlined"
              color="info"
              size="small"
              sx={{ padding: 0, minWidth: 30, mr: 1 }}
              onClick={showTaxes}
            >
              {lines.taxAndCharges?.[data.id]?.taxes?.length ?? 0}
            </Button>
            <Typography variant={'body1'} color={'primary'}>
              {currencyFormatter.format(Number(total))}
            </Typography>
          </div>
        )
      }
    })
  }

  const FIELD_LEVEL = 'lineItem'
  const detailFields = Object.values(ColumnsOrderLineItemsStandard)
    .filter(x => x.section === 'details')
    .map(x => x.type)

  const customFieldsSelects = Object.values(requiredFields ?? {}).filter(
    x => x.level === FIELD_LEVEL && x.isVisible && x.isCustomField
  )

  const standardFields = Object.values(requiredFields ?? {}).filter(
    x => detailFields.includes(x.standardField) && x.isVisible && x.level === FIELD_LEVEL
  )

  /* const isLineAccountSegmentsVisible =
    requiredFields[`${FIELD_LEVEL}#${ColumnsOrderLineItemsStandard.accounting.type}`]?.visible */
  const accountingRule = getStandardRule({
    requiredFields,
    level: 'lineItem',
    standardField: ColumnsOrderLineItemsStandard.accounting.type
  })

  customFieldsSelects.forEach(x => {
    columns.push({
      header: x.displayName,
      name: x.standardField,
      defaultFlex: 1,
      minWidth: 100,
      editable: false,
      defaultVisible: false
    })
  })

  standardFields.forEach(x => {
    switch (x.standardField) {
      case ColumnsOrderLineItemsStandard['supplier.shipToAddress.fullAddress'].type:
        /* columns.push({
          header: x.displayName,
          name: ColumnsOrderLineItemsStandard['supplier.shipToAddress.fullAddress'].type,
          defaultFlex: 1,
          minWidth: 100,
          editable: false,
          defaultVisible: false
        })
        columns.push({
          header: x.displayName,
          name: ColumnsOrderLineItemsStandard['supplier.shipToAddress.id'].section,
          defaultFlex: 1,
          minWidth: 100,
          editable: false,
          defaultVisible: false
        }) */
        break
      case ColumnsOrderLineItemsStandard['companyProfile.shipToAddress.fullAddress'].type:
        /* columns.push({
          header: x.displayName,
          name: ColumnsOrderLineItemsStandard['companyProfile.shipToAddress.fullAddress'].type,
          defaultFlex: 1,
          minWidth: 100,
          editable: false,
          defaultVisible: false
        })
        columns.push({
          header: x.displayName,
          name: ColumnsOrderLineItemsStandard['companyProfile.shipToAddress.id'].type,
          defaultFlex: 1,
          minWidth: 100,
          editable: false,
          defaultVisible: false
        }) */
        break
      case ColumnsOrderLineItemsStandard.accounting.type:
        if (accountSegmentsEnabled) {
          columns.push({
            header: x.displayName,
            name: ColumnsOrderLineItemsStandard.accounting.type,
            defaultFlex: 1,
            minWidth: 100,
            editable: false,
            defaultVisible: false
          })
        }
        break

      default:
        columns.push({
          header: x.displayName,
          name: x.standardField,
          defaultFlex: 1,
          minWidth: 100,
          editable: false,
          defaultVisible: false
        })
        break
    }
  })

  const detailsFields = standardFields.filter(
    x => x.standardField !== ColumnsOrderLineItemsStandard.accounting.type
  )
  const isAccountEnabled = accountingRule?.isVisible && accountSegmentsEnabled

  const isDetailsEnabled = detailsFields.length > 0 || customFieldsSelects.length > 0

  if (isDetailsEnabled || isAccountEnabled) {
    columns.push({
      header: ColumnsOrderLineItemsStandard.additionalDetails.label,
      name: ColumnsOrderLineItemsStandard.additionalDetails.type,
      defaultFlex: 1,
      minWidth: 250,
      editable: false,
      textAlign: 'center',
      defaultVisible: true,
      render: ({ _value, data }) => {
        let iconAccount = null
        let isErrorAccount = false
        let typeMsgAccount
        let typeMessage = ''
        const columnNameAccount = ColumnsOrderLineItemsStandard.accounting.type
        if (accountSegmentsEnabled) {
          const lineErrors = lines.errors.find(x => x.id === data.id)

          const fieldError = lineErrors?.errors?.find(x => x.field === columnNameAccount)

          const typeMsgAccount = fieldError?.details?.find(x => x.criticalityType === 'critical')

          if (typeMsgAccount) {
            typeMessage = 'critical'
          }

          if (fieldError && typeMsgAccount) {
            isErrorAccount = true
          }

          iconAccount = typeMessage ? (
            <ErrorIcon style={{ color: 'red' }} />
          ) : (
            <WarningIcon style={{ color: '#f3bc07' }} />
          )
        }

        const specialFields = [...customFieldsSelects.map(x => x.standardField)]

        /*  const specialFields = [
          ...customFieldsSelects.map(x => x.standardField),
          ...standardFields
            .filter(x => x.standardField !== ColumnsOrderLineItemsStandard.accounting.type)
            .map(x => x.standardField)
        ] */

        let isError = false
        let icon = null

        // eslint-disable-next-line
        for (const specialField of specialFields) {
          const lineErrors = lines.errors.find(x => x.id === data.id)
          const fieldError = lineErrors?.errors?.find(x => x.field === specialField)

          if (!fieldError) {
            continue
          }
          const criticalType = fieldError?.details?.find(x => x.criticalityType === 'critical')
          if (criticalType) {
            typeMessage = 'critical'
          }

          icon = criticalType ? (
            <ErrorIcon style={{ color: 'red' }} />
          ) : (
            <WarningIcon style={{ color: '#f3bc07' }} />
          )

          if (fieldError && typeMessage) {
            isError = true
            break
          }
        }

        return (
          <div style={{ display: 'inline-block' }}>
            <ButtonGroup variant="text" size="small" aria-label="Lite items details">
              {isAccountEnabled ? (
                <Button
                  color="primary"
                  onClick={() => showAccountSegments()}
                  endIcon={
                    isErrorAccount ? (
                      <IconButton
                        size="small"
                        component="span"
                        onClick={() =>
                          setCreateError({
                            isOpen: true,
                            message: typeMsgAccount?.message,
                            color: typeMessage === 'warning' ? 'warning' : 'danger'
                          })
                        }
                      >
                        {iconAccount}
                      </IconButton>
                    ) : null
                  }
                >
                  Accounting
                </Button>
              ) : null}

              {isDetailsEnabled ? (
                <Button
                  color="primary"
                  onClick={() => showAddressesModal()}
                  endIcon={
                    isError ? (
                      <IconButton size="small" component="span">
                        {icon}
                      </IconButton>
                    ) : null
                  }
                >
                  Details
                </Button>
              ) : null}
            </ButtonGroup>
          </div>
        )
      }
    })
  }

  let indexColumn = 1
  columns.forEach(element => {
    const type = element.name
    if (type && element.defaultVisible) {
      columnPos[type] = indexColumn
      indexColumn++
    }
  })
  return { columns, columnPos }
}

export function getAdditionalColumns(params) {
  const {
    lines,
    classes,
    setCreateError,
    requiredFields,
    appConfig,
    processValue,
    onChangeType,
    type
  } = params

  const columns = []
  const columnPos = {}

  const lineItemsFields = requiredFields.filter(x => x.level === type)

  const lineItemsFieldsSorted = lineItemsFields
    .map(x => ({
      ...x,
      pos: ColumnsOrderChargesTaxesStandard[x.standardField]?.pos ?? lineItemsFields.length
    }))
    .sort(function sortElements(a, b) {
      return a.pos - b.pos
    })

  columns.push({
    header: 'id',
    name: 'id',
    defaultFlex: 1,
    minWidth: 100,
    editable: false,
    defaultVisible: false
  })

  let position = 0

  // eslint-disable-next-line
  for (const field of lineItemsFieldsSorted) {
    if (!appConfig.data.params.invoices.showTaxRate && field.standardField === 'rate') {
      continue
    }

    columnPos[field.standardField] = position
    position++

    if (field.standardField === 'type' || field.standardField === 'taxCode') {
      const options = []
      options.push(
        <option key={'none'} value="">
          No Match
        </option>
      )

      let specialPOLines
      if (type === 'additionalCharge') {
        specialPOLines = chargesTypesByProcess[processValue] ?? []
      } else {
        specialPOLines = taxesTypesByProcess[processValue] ?? []
      }

      // eslint-disable-next-line
      for (const line of specialPOLines) {
        options.push(
          <option key={line.value} value={line.value}>
            {line.label}
          </option>
        )
      }

      columns.push({
        name: field.standardField,
        header: field.displayName,
        defaultFlex: 1,
        minWidth: 125,
        editable: false,
        defaultVisible: field.isVisible,
        render: ({ value, data }) => (
          <select
            name="type"
            value={value}
            id="type"
            className={classes.dropdown}
            onChange={e => onChangeType(data.id, e.target.value)}
          >
            {options.length > 0 ? options : null}
          </select>
        )
      })

      continue
    }

    let minWidth = 120

    if (field.standardField === 'description') {
      minWidth = 400
    }

    columns.push({
      name: field.standardField,
      header: field.displayName,
      defaultFlex: 1,
      minWidth: minWidth,
      defaultVisible: field.isVisible,
      render: ({ value, data }) => {
        const lineErrors = lines.errors.find(x => x.id === data.id)

        const fieldError = lineErrors?.errors?.find(x => x.field === field.standardField)

        if (!fieldError) {
          return value
        }

        let typeMessage = ''
        const criticalType = fieldError.details.find(x => x.criticalityType === 'critical')

        if (criticalType) {
          typeMessage = 'critical'
        }

        const colorInput = getColor(typeMessage)

        const icon = criticalType ? (
          <ErrorIcon style={{ color: 'red' }} />
        ) : (
          <WarningIcon style={{ color: '#f3bc07' }} />
        )

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div style={{ color: colorInput }}>{value}</div>
            <div
              style={{
                color: colorInput,
                textAlign: 'right'
              }}
            >
              <IconButton
                size="small"
                component="span"
                onClick={() =>
                  setCreateError({
                    isOpen: true,
                    message: criticalType.message,
                    color: typeMessage === 'warning' ? 'warning' : 'danger'
                  })
                }
              >
                {icon}
              </IconButton>
            </div>
          </div>
        )
      }
    })
  }

  return { columns, columnPos }
}

function linesReducer(state, action) {
  const { lines, pagination, selectedLine } = state
  const { type, payload } = action

  switch (type) {
    case actionTypes.CLONE_LINE: {
      const updatedLines = [...lines]
      const { columns, data } = payload
      const newElement = {}
      // eslint-disable-next-line
      for (const column of columns) {
        if (column.name === 'id') {
          newElement[column.name] = uuidv4()
          continue
        }
        if (column.name === 'lineNumber') {
          newElement[column.name] = ''
          continue
        }
        newElement[column.name] = data[column.name] ?? ''
      }

      updatedLines.push(newElement)

      const newNumberOfPages = Math.ceil(updatedLines.length / pagination.dataPerPage)
      const newSkip = (newNumberOfPages - 1) * pagination.dataPerPage

      const newError = state.errors.find(x => x.id !== data.id)
      const updatedErrors = state.errors.filter(x => x.id !== data.id)

      if (newError) {
        newError.id = newElement.id
        updatedErrors.push(newError)
      }
      const taxChargesMap = new Map(Object.entries(state.taxAndCharges ?? {}))
      const oldTaxesCharges = taxChargesMap.get(data.id)
      if (oldTaxesCharges) {
        const newChargesTaxes = { ...oldTaxesCharges }
        newChargesTaxes.id = newElement.id
        taxChargesMap.set(newElement.id, newChargesTaxes)
      }

      return {
        ...state,
        lines: updatedLines,
        errors: updatedErrors,
        pagination: {
          ...state.pagination,
          skip: newSkip,
          numberOfPages: newNumberOfPages,
          currentPage: newNumberOfPages
        },
        taxAndCharges: Object.fromEntries(taxChargesMap)
      }
    }
    case actionTypes.SPLIT_LINE: {
      const { columns, data } = payload
      const newLineItems = state.lines.filter(x => x.id !== data.id)

      const cantLineItems = Number(data.quantity ?? '0')
      let unitPrice = data.unitPrice
      const total = data.total

      if (cantLineItems === 0 || cantLineItems === 1 || cantLineItems < 0) {
        return { ...state }
      }

      if (!unitPrice && !total) {
        return { ...state }
      }

      if (!unitPrice && total) {
        unitPrice = `${Number(total) / cantLineItems}`
      }

      newLineItems.push({
        ...data,
        quantity: '1',
        unitPrice: unitPrice,
        total: unitPrice
      })

      for (let index = 0; index < cantLineItems - 1; index++) {
        const newElement = {}
        // eslint-disable-next-line
        for (const column of columns) {
          if (column.name === 'id') {
            newElement[column.name] = uuidv4()
            continue
          }
          if (column.name === 'lineNumber') {
            newElement[column.name] = ''
            continue
          }

          if (column.name === 'quantity') {
            newElement[column.name] = '1'
            continue
          }

          if (column.name === 'unitPrice' || column.name === 'total') {
            newElement[column.name] = unitPrice
            continue
          }
          newElement[column.name] = data[column.name] ?? ''
        }
        newLineItems.push(newElement)
      }

      const newNumberOfPages = Math.ceil(newLineItems.length / pagination.dataPerPage)
      const newSkip = (newNumberOfPages - 1) * pagination.dataPerPage

      return {
        ...state,
        lines: newLineItems,
        pagination: {
          ...state.pagination,
          skip: newSkip,
          numberOfPages: newNumberOfPages,
          currentPage: newNumberOfPages
        }
      }
    }
    case actionTypes.MERGE: {
      const linesToDelete = getLinesToEliminate(selectedLine, state)

      if (linesToDelete.length === 0) {
        return { ...state }
      }

      linesToDelete.sort((first, second) => {
        return Number(first.lineNumber ?? 10000) - Number(second.lineNumber ?? 10000)
      })

      const firstRow = linesToDelete[0]
      let linesUpdated = [...state.lines]
      const firstRowTemp = { ...firstRow }
      linesToDelete.shift()
      const linesToDeleteId = linesToDelete.map(x => x.id)

      // eslint-disable-next-line
      for (const [key, value] of Object.entries(firstRow)) {
        if (generateConditionalMerge(key, value, true)) {
          continue
        }
        // eslint-disable-next-line
        for (const row of linesToDelete) {
          const valueRow = row[key]
          if (valueRow) {
            firstRowTemp[key] = valueRow
            break
          }
        }
      }

      const index = state.lines.findIndex(item => item.id === firstRow.id)
      if (index < 0) {
        return { ...state }
      }
      linesUpdated[index] = firstRowTemp

      linesUpdated = linesUpdated.filter(item => !linesToDeleteId.includes(item.id))

      const updatedErrors = state.errors.filter(item => !linesToDeleteId.includes(item.id))

      const newNumberOfPages = Math.ceil(linesUpdated.length / pagination.dataPerPage)

      const newSkip = (newNumberOfPages - 1) * pagination.dataPerPage
      return {
        ...state,
        lines: linesUpdated,
        errors: updatedErrors,
        pagination: {
          ...state.pagination,
          skip: newSkip,
          numberOfPages: newNumberOfPages,
          currentPage: newNumberOfPages
        }
      }
    }
    case actionTypes.CONSOLIDATE: {
      const linesToDelete = getLinesToEliminate(selectedLine, state)

      if (linesToDelete.length === 0) {
        return { ...state }
      }

      linesToDelete.sort((first, second) => {
        return Number(first.lineNumber ?? 10000) - Number(second.lineNumber ?? 10000)
      })

      const firstRow = linesToDelete[0]
      const firstRowTemp = { ...firstRow }
      let linesUpdated = [...state.lines]
      linesToDelete.shift()

      const linesToDeleteId = linesToDelete.map(x => x.id)

      // eslint-disable-next-line
      for (const [key, value] of Object.entries(firstRow)) {
        if (generateConditionalMerge(key, value, false)) {
          continue
        }
        // eslint-disable-next-line
        for (const row of linesToDelete) {
          const valueRow = row[key]
          if (valueRow) {
            firstRowTemp[key] = valueRow
            break
          }
        }
      }

      switch (state.type) {
        case 'additionalCharges':
          {
            const totalValues = linesToDelete.map(x => Number(x.total ?? 0))
            totalValues.push(Number(firstRow.total ?? 0))
            const totalValue = maxDecimalAndSummation(totalValues).summation
            firstRowTemp.total = totalValue
          }
          break
        case 'lineItem':
          {
            const totalValues = linesToDelete.map(x => Number(x.total ?? 0))
            totalValues.push(Number(firstRow.total ?? 0))
            const totalValue = maxDecimalAndSummation(totalValues).summation
            firstRowTemp.quantity = '1'
            firstRowTemp.unitPrice = totalValue
            firstRowTemp.total = totalValue
          }
          break

        default:
          break
      }

      const index = state.lines.findIndex(item => item.id === firstRow.id)
      if (index < 0) {
        return { ...state }
      }
      linesUpdated[index] = firstRowTemp

      linesUpdated = linesUpdated.filter(item => !linesToDeleteId.includes(item.id))

      const updatedErrors = state.errors.filter(item => !linesToDeleteId.includes(item.id))

      const newNumberOfPages = Math.ceil(linesUpdated.length / pagination.dataPerPage)

      const newSkip = (newNumberOfPages - 1) * pagination.dataPerPage
      return {
        ...state,
        lines: linesUpdated,
        errors: updatedErrors,
        pagination: {
          ...state.pagination,
          skip: newSkip,
          numberOfPages: newNumberOfPages,
          currentPage: newNumberOfPages
        }
      }
    }
    case actionTypes.DELETE_LINES: {
      const linesToDelete = getLinesToEliminate(selectedLine, state)

      if (linesToDelete.length === 0) {
        return { ...state }
      }
      const taxChargesMap = new Map(Object.entries(state.taxAndCharges ?? {}))
      const linesToDeleteId = linesToDelete.map(x => x.id)
      const linesUpdated = state.lines.filter(item => !linesToDeleteId.includes(item.id))

      const updatedErrors = state.errors.filter(item => !linesToDeleteId.includes(item.id))

      const newNumberOfPages = Math.ceil(linesUpdated.length / pagination.dataPerPage)
      // eslint-disable-next-line
      for (const lineId of linesToDeleteId) {
        if (lineId) {
          taxChargesMap.delete(lineId)
        }
      }

      return {
        ...state,
        lines: linesUpdated,
        pagination: {
          ...state.pagination,
          numberOfPages: newNumberOfPages,
          currentPage: newNumberOfPages
        },
        selectedLine: {
          cell: null,
          selected: {},
          unselected: {}
        },
        errors: updatedErrors,
        taxAndCharges: Object.fromEntries(taxChargesMap)
      }
    }
    case actionTypes.ADD_LINE: {
      const { columns, requiredFields } = payload
      if (lines.length > 0) {
        let isEmpty = true
        const lastItem = lines[lines.length - 1]
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(lastItem)) {
          if (key === 'id') {
            continue
          }
          if (value !== '') {
            isEmpty = false
          }
        }

        if (isEmpty) {
          return { ...state }
        }
      }

      const copyLines = [...lines]

      const newElement = {}

      const errorsLine = []
      const lineKey = uuidv4()
      // eslint-disable-next-line
      for (const column of columns) {
        if (column.name === 'id') {
          newElement[column.name] = lineKey
          continue
        }
        const rule = requiredFields.find(
          x => x.level === state.type && x.standardField === column.name
        )
        const isRequired = rule?.validations?.some(x => x.type === 'required')
        if (isRequired) {
          let label = ''
          label = label ?? column.name
          errorsLine.push({
            message: 'Schema Validation',
            details: [
              {
                message: `${rule.displayName} field is required`,
                criticalityType: 'critical'
              }
            ],
            action: 'internalException',
            field: column.name,
            origin: 'internal',
            ruleId: ''
          })
        }

        newElement[column.name] = ''
      }
      copyLines.push(newElement)

      const newNumberOfPages = Math.ceil(copyLines.length / pagination.dataPerPage)
      const newSkip = (newNumberOfPages - 1) * pagination.dataPerPage

      return {
        ...state,
        lines: copyLines,
        errors: [...state.errors, { id: lineKey, errors: errorsLine }],
        pagination: {
          ...state.pagination,
          skip: newSkip,
          numberOfPages: newNumberOfPages,
          currentPage: newNumberOfPages
        }
      }
    }
    case actionTypes.EDIT: {
      const { columnId, requiredFields, setCreateError, value, data } = payload
      const rule = requiredFields.find(x => x.level === state.type && x.standardField === columnId)

      const label = rule?.displayName ?? ''
      const copyLineItems = [...state.lines]

      const validate = validateInputV2(rule?.validations ?? [], value)

      if (!validate.valid) {
        setCreateError({
          message: validate.message ?? `Invalid Input for ${label}`,
          isOpen: true,
          color: 'danger'
        })
        return { ...state }
      }

      const lineItemIndex = state.lines.findIndex(line => line.id === data?.id)

      if (lineItemIndex < 0) {
        return { ...state }
      }
      copyLineItems[lineItemIndex][columnId] = value

      const lineErrors = state.errors.find(x => x.id === data.id)
      const updatedErrors = state.errors.filter(x => x.id !== data.id)
      if (lineErrors) {
        const fieldsErrors = lineErrors.errors.filter(x => x.field !== columnId)
        updatedErrors.push({ ...lineErrors, errors: fieldsErrors })
      }
      return {
        ...state,
        lines: copyLineItems,
        errors: updatedErrors
      }
    }
    case actionTypes.EDIT_FIELDS: {
      const { lines } = payload
      const copyLineItems = [...state.lines]
      let updatedErrors = [...state.errors]
      // eslint-disable-next-line
      for (const line of lines) {
        const indexLine = copyLineItems.findIndex(x => x.id === line.id)

        if (indexLine < 0) {
          continue
        }
        const lineErrors = state.errors.find(x => x.id === line.id)
        updatedErrors = updatedErrors.filter(x => x.id !== line.id)
        // eslint-disable-next-line
        for (const field of line.fields) {
          copyLineItems[indexLine][field.column] = field.value
          if (lineErrors) {
            const fieldsErrors = lineErrors.errors.filter(x => x.field !== field.column)
            if (fieldsErrors.length > 0) {
              updatedErrors.push({ ...lineErrors, errors: fieldsErrors })
            }
          }
        }
      }

      return {
        ...state,
        lines: copyLineItems,
        errors: updatedErrors
      }
    }
    case actionTypes.SET_SELECTED_LINES: {
      const { selected, unselected, cellSelected } = payload

      let id = state.selectedLine.id
      if (cellSelected) {
        id =
          Object.keys(cellSelected ?? {}).length === 1
            ? Object.keys(cellSelected ?? {})[0].split(',')
            : []
      }
      return {
        ...state,
        selectedLine: {
          cell: cellSelected ?? state.selectedLine.cell,
          id,
          selected: selected ?? state.selectedLine.selected,
          unselected: unselected ?? state.selectedLine.unselected
        }
      }
    }
    case actionTypes.SET_DATA: {
      const { lines, errors, requiredFields } = payload

      let linesUpdated = lines
      const lineItemsAccountSegments = {}
      const taxAndCharges = {}
      if (state.type === 'lineItem') {
        const lineItemsFields = requiredFields.filter(x => x.level === state.type)
        linesUpdated = []
        // eslint-disable-next-line
        for (const line of lines) {
          const data = {}
          // eslint-disable-next-line
          for (const field of lineItemsFields) {
            let standardField = field.standardField
            if (standardField.includes('CUSTOM_')) {
              standardField = `custom.${standardField}`
            }
            const value = get(line, standardField)
            data[field.standardField] = value ?? ''
          }
          data.id = line.id
          linesUpdated.push(data)
          taxAndCharges[line.id] = {
            id: line.id,
            taxes: line.taxLines?.map(x => ({ ...x, amount: `${x.amount}`, rate: `${x.rate}` })),
            charges: line.additionalCharges?.map(x => ({ ...x, total: `${x.total}` }))
          }
          lineItemsAccountSegments[line.id] = {
            id: line.id,
            accountSegments:
              line.accounting?.map(x => ({
                id: x.splitAccountId ?? '',
                accountId: x.accountId,
                accountTypeId: x.accountTypeId,
                segments: x.segments.reduce((acc, segment) => {
                  acc[segment.segmentId] = segment.segmentValue
                  return acc
                }, {})
              })) ?? []
          }
        }
      }

      return {
        ...state,
        lines: linesUpdated,
        errors,
        pagination: {
          ...state.pagination,
          numberOfPages: Math.ceil(lines.length / pagination.dataPerPage)
        },
        selectedLine: {
          cell: null,
          id: null,
          selected: {},
          unselected: {}
        },
        lineItemsAccountSegments,
        taxAndCharges
      }
    }
    case actionTypes.UPDATE_PAGINATION: {
      const { type } = payload

      let newSkip
      let tempPage
      if (type === 'next') {
        newSkip = pagination.skip + pagination.dataPerPage
        tempPage = pagination.currentPage + 1
      } else {
        tempPage = pagination.currentPage - 1
        newSkip = pagination.skip - pagination.dataPerPage
      }

      newSkip = Math.min(newSkip, state.lines.length + 1)
      newSkip = Math.max(newSkip, 0)

      return {
        ...state,
        pagination: {
          ...state.pagination,
          skip: newSkip,
          numberOfPages: pagination.numberOfPages,
          currentPage: tempPage
        }
      }
    }
    case actionTypes.UPDATE_LINES: {
      const { payload } = action
      const { data, requiredFields, columns, deletedLines } = payload
      const lineItemsData = Object.entries(data.lines ?? {})

      const accountingLineItemsData = Object.values(data.accounting ?? {})

      const rowsToRemoveErrors: { columnId: string, lineItemKey: string }[] = []
      const copyLineItems = [...state.lines]
      let newLineItems = [...copyLineItems]

      let rowsToDelete = []
      const newErrors = []
      if (lineItemsData.length > 0) {
        // eslint-disable-next-line
        for (const [key, value] of lineItemsData) {
          const lineData = Object.values(value)
          const lineIndex = copyLineItems.findIndex(x => x.id === key)
          if (lineIndex < 0) {
            const newElement: { [key: string]: string } = {}

            const lineKey = uuidv4()
            // eslint-disable-next-line
            for (const column of columns) {
              if (!column) {
                continue
              }
              if (column === 'id') {
                newElement[column] = lineKey
                continue
              }
              newElement[column] = ''
            }
            lineData.forEach(function(data) {
              newElement[data.field] = data.value
            })

            Object.keys(newElement).forEach(columnName => {
              const rule = requiredFields.find(
                x => x.level === state.type && x.standardField === columnName
              )
              const isRequired = rule?.validations?.some(x => x.type === 'required')
              if (isRequired && !newElement[columnName]) {
                newErrors.push({
                  id: newElement.id,
                  errors: [
                    {
                      message: 'Schema Validation',
                      details: [
                        {
                          message: `${rule.displayName} field is required`,
                          criticalityType: 'critical'
                        }
                      ],
                      action: 'internalException',
                      field: columnName,
                      origin: 'internal',
                      ruleId: ''
                    }
                  ]
                })
              }
            })

            newLineItems.push(newElement)
          } else {
            const lineUpdated = { ...copyLineItems[lineIndex] }
            lineData.forEach(function(data) {
              lineUpdated[data.field] = data.value
              rowsToRemoveErrors.push({
                columnId: data.field,
                lineItemKey: key
              })
            })
            if (newLineItems.length > 0) {
              newLineItems[lineIndex] = lineUpdated
            }
          }
        }
      }

      const copyAccountingLineItems = { ...state.lineItemsAccountSegments }
      if (accountingLineItemsData.length > 0) {
        accountingLineItemsData.forEach(function(data) {
          if (copyAccountingLineItems[data.id]) {
            copyAccountingLineItems[data.id] = {
              ...copyAccountingLineItems[data.id],
              accountSegments: data.accountSegments.map(x => {
                const oldAccountSegments = copyAccountingLineItems[data.id].accountSegments?.find(
                  newSegment => newSegment.id === x.id
                )
                if (oldAccountSegments) {
                  return {
                    ...x,
                    accountId: x.accountId ? x.accountId : 'relishTBD',
                    segments: {
                      ...oldAccountSegments.segments,
                      ...x.segments
                    }
                  }
                }
                return {
                  ...x,
                  accountId: x.accountId ? x.accountId : 'relishTBD'
                }
              })
            }
          } else {
            copyAccountingLineItems[data.id] = {
              ...data,
              accountSegments: data.accountSegments.map(x => ({
                ...x,
                accountId: x.accountId ? x.accountId : 'relishTBD'
              }))
            }
          }

          rowsToRemoveErrors.push({
            columnId: ColumnsOrderLineItemsStandard.accounting.type,
            lineItemKey: data.id
          })
        })
      }

      let copyErrors = [...state.errors, ...newErrors]
      // eslint-disable-next-line
      for (const errorDeleted of rowsToRemoveErrors) {
        const lineErrors = copyErrors.find(x => x.id === errorDeleted.lineItemKey)
        copyErrors = copyErrors.filter(x => x.id !== errorDeleted.lineItemKey)
        if (lineErrors) {
          const fieldsErrors = lineErrors.errors.filter(x => x.field !== errorDeleted.columnId)
          if (fieldsErrors.length > 0) {
            copyErrors.push({ ...lineErrors, errors: fieldsErrors })
          }
        }
      }

      if (deletedLines && deletedLines?.length > 0) {
        newLineItems = newLineItems.filter(item => item.id && !deletedLines.includes(item.id))
        // eslint-disable-next-line
        for (const id of deletedLines) {
          copyErrors = copyErrors.filter(x => x.id === id)
        }

        const newRowsToDelete = Object.values(newLineItems).filter(
          x => x.id && !deletedLines.includes(x.id)
        )

        rowsToDelete.push(...newRowsToDelete)
      }

      return {
        ...state,
        pagination: {
          dataPerPage: 20,
          skip: 0,
          numberOfPages: Math.ceil(newLineItems.length / pagination.dataPerPage),
          currentPage: 1
        },
        selectedLine: {
          cell: null,
          id: null,
          selected: {},
          unselected: {}
        },
        lines: newLineItems,
        lineItemsAccountSegments: copyAccountingLineItems,
        errors: copyErrors
      }
    }
    case actionTypes.SET_PO_LINES: {
      const { data } = payload
      const linesCopy = [...state.lines]
      const po = Object.values(data.lineItemsPo ?? {})
      let copyErrors = [...state.errors]
      const descriptionDetailsPO = po.map(line => (line.description ?? '').toLowerCase())

      if (descriptionDetailsPO.length === 0) {
        // eslint-disable-next-line
        for (const [i, v] of linesCopy.entries()) {
          if (specialPOLines.some(line => line.value === linesCopy[i]['poLineNumber'])) {
            continue
          }
          linesCopy[i]['poLineNumber'] = ''
        }
      } else {
        // eslint-disable-next-line
        for (const [i, v] of linesCopy.entries()) {
          if (specialPOLines.some(line => line.value === linesCopy[i]['poLineNumber'])) {
            continue
          }

          if (!v.description) {
            continue
          }
          const matches = findBestMatch(v.description.toLowerCase(), descriptionDetailsPO)
          const { bestMatchIndex } = matches

          const targetPo = po[bestMatchIndex]
          linesCopy[i]['poLineNumber'] = targetPo.lineNumber
          const lineErrors = copyErrors.find(x => x.id === data.id)
          if (lineErrors) {
            copyErrors = copyErrors.filter(x => x.id !== v.id)
            lineErrors.errors = lineErrors.errors.filter(x => x.field !== 'poLineNumber')
            if (lineErrors.errors.length > 0) {
              copyErrors.push(lineErrors)
            }
          }
        }
      }

      return {
        ...state,
        lines: linesCopy,
        errors: copyErrors
      }
    }
    case actionTypes.UPDATE_ACCOUNTING: {
      const { payload } = action

      const { id, account } = payload

      const copyAccount = { ...(state.lineItemsAccountSegments ?? {}) }
      copyAccount[id] = {
        id,
        accountSegments: account
      }
      const lineErrors = state.errors.find(x => x.id === id)
      const updatedErrors = state.errors.filter(x => x.id !== id)
      if (lineErrors) {
        const fieldsErrors = lineErrors.errors.filter(
          x => x.field !== ColumnsOrderLineItemsStandard.accounting.type
        )
        if (fieldsErrors.length > 0) {
          updatedErrors.push({ ...lineErrors, errors: fieldsErrors })
        }
      }
      return {
        ...state,
        lineItemsAccountSegments: copyAccount,
        errors: updatedErrors
      }
    }
    case actionTypes.VALIDATE_ACCOUNT_SEGMENTS: {
      const { payload } = action
      const { requiredFields } = payload

      let newErrorState = [...state.errors]

      const rule = getStandardRule({
        requiredFields,
        level: 'lineItem',
        standardField: ColumnsOrderLineItemsStandard.accounting.type
      })
      const requiredField = getValidationRule(rule, 'required')

      // eslint-disable-next-line
      for (const row of Object.values(state.lines)) {
        if (!row.id) {
          continue
        }

        let isValidLineAccount = true
        const accountSegment = state.lineItemsAccountSegments?.[row.id]

        if (!accountSegment || accountSegment.accountSegments.length === 0) {
          if (rule && requiredField) {
            const lineErrors = newErrorState.find(x => x.id === row.id)

            if (lineErrors) {
              newErrorState = newErrorState.filter(x => x.id !== row.id)
              const accountingError = lineErrors.errors.find(
                x => x.field === ColumnsOrderLineItemsStandard.accounting.type
              )
              if (!accountingError) {
                lineErrors.errors.push({
                  message: 'Schema Validation',
                  details: [
                    {
                      message: `${rule.displayName} field is required`,
                      criticalityType: 'critical'
                    }
                  ],
                  action: 'internalException',
                  field: ColumnsOrderLineItemsStandard.accounting.type,
                  origin: 'internal',
                  ruleId: ''
                })
              }

              newErrorState.push({ ...lineErrors })
            } else {
              newErrorState.push({
                id: row.id,
                errors: [
                  {
                    message: 'Schema Validation',
                    details: [
                      {
                        message: `${rule.displayName} field is required`,
                        criticalityType: 'critical'
                      }
                    ],
                    action: 'internalException',
                    field: ColumnsOrderLineItemsStandard.accounting.type,
                    origin: 'internal',
                    ruleId: ''
                  }
                ]
              })
            }
          }

          continue
        }
        isValidLineAccount = accountSegment.accountSegments.every((x: any) => x.accountId)
        if (!isValidLineAccount && requiredField && rule) {
          const lineErrors = newErrorState.find(x => x.id === row.id)

          if (lineErrors) {
            newErrorState = newErrorState.filter(x => x.id !== row.id)
            const accountingError = lineErrors.errors.find(
              x => x.field === ColumnsOrderLineItemsStandard.accounting.type
            )
            if (!accountingError) {
              lineErrors.errors.push({
                message: 'Schema Validation',
                details: [
                  {
                    message: `${rule.displayName} field is required`,
                    criticalityType: 'critical'
                  }
                ],
                action: 'internalException',
                field: ColumnsOrderLineItemsStandard.accounting.type,
                origin: 'internal',
                ruleId: ''
              })
            }

            newErrorState.push({ ...lineErrors })
          } else {
            newErrorState.push({
              id: row.id,
              errors: [
                {
                  message: 'Schema Validation',
                  details: [
                    {
                      message: `${rule.displayName} field is required`,
                      criticalityType: 'critical'
                    }
                  ],
                  action: 'internalException',
                  field: ColumnsOrderLineItemsStandard.accounting.type,
                  origin: 'internal',
                  ruleId: ''
                }
              ]
            })
          }

          continue
        }
      }

      return {
        ...state,
        errors: newErrorState
      }
    }
    case actionTypes.UPDATE_LINE_TAXES: {
      const { payload } = action

      const selectedLine =
        Object.keys(state.selectedLine.cell ?? {}).length === 1
          ? Object.keys(state.selectedLine.cell ?? {})[0].split(',')
          : []

      if (selectedLine.length === 0) {
        return { ...state }
      }

      const lineSelected = state.lines.find(x => x.id === selectedLine[0])

      if (!lineSelected?.id) {
        return { ...state }
      }
      const newTaxesLines = { ...state.taxAndCharges }
      const oldTaxesCharges = state.taxAndCharges?.[lineSelected.id]
      const newTaxesLine = oldTaxesCharges
        ? { ...oldTaxesCharges }
        : { id: lineSelected.id, taxes: [], charges: [] }

      newTaxesLine.taxes = payload
      newTaxesLines[lineSelected.id] = newTaxesLine

      return {
        ...state,
        taxAndCharges: newTaxesLines
      }
    }
    default:
      throw new Error('Event not implemented yet')
  }
}

const useStyles = makeStyles(styles)
export function useLines(initialPresent) {
  const classes = useStyles()
  const [state, dispatch] = React.useReducer(linesReducer, {
    type: initialPresent.type,
    lines: [],
    pagination: {
      dataPerPage: initialPresent.dataPerPage,
      skip: 0,
      numberOfPages: 0,
      currentPage: 1
    },
    selectedLine: {
      cell: null,
      id: null,
      selected: {},
      unselected: {}
    },
    errors: []
  })

  const cloneLine = React.useCallback(
    payload => dispatch({ type: actionTypes.CLONE_LINE, payload }),
    []
  )
  const splitLine = React.useCallback(
    payload => dispatch({ type: actionTypes.SPLIT_LINE, payload }),
    []
  )
  const mergeLines = React.useCallback(() => dispatch({ type: actionTypes.MERGE }), [])
  const consolidateLines = React.useCallback(() => dispatch({ type: actionTypes.CONSOLIDATE }), [])
  const deleteLines = React.useCallback(() => dispatch({ type: actionTypes.DELETE_LINES }), [])
  const addLine = React.useCallback(
    payload => dispatch({ type: actionTypes.ADD_LINE, payload }),
    []
  )
  const editLine = React.useCallback(payload => dispatch({ type: actionTypes.EDIT, payload }), [])

  const editLines = React.useCallback(
    payload => dispatch({ type: actionTypes.EDIT_FIELDS, payload }),
    []
  )
  const setSelectedLines = React.useCallback(
    payload => dispatch({ type: actionTypes.SET_SELECTED_LINES, payload }),
    []
  )
  const setData = React.useCallback(
    payload => dispatch({ type: actionTypes.SET_DATA, payload }),
    []
  )

  const updatePagination = React.useCallback(
    payload => dispatch({ type: actionTypes.UPDATE_PAGINATION, payload }),
    []
  )

  const updatePoLines = React.useCallback(
    payload => dispatch({ type: actionTypes.SET_PO_LINES, payload }),
    []
  )

  const updateAccount = React.useCallback(
    payload => dispatch({ type: actionTypes.UPDATE_ACCOUNTING, payload }),
    []
  )

  const validateAccountSegments = React.useCallback(
    payload => dispatch({ type: actionTypes.VALIDATE_ACCOUNT_SEGMENTS, payload }),
    []
  )

  const updateLines = React.useCallback(
    payload => dispatch({ type: actionTypes.UPDATE_LINES, payload }),
    []
  )

  const updateTaxesLine = React.useCallback(
    payload => dispatch({ type: actionTypes.UPDATE_LINE_TAXES, payload }),
    []
  )

  function getColors(error, isDirty) {
    let color = ''
    let inputColor = ''
    let badgeColor = ''
    let tooltipColor = ''

    if (isDirty && !error) {
      color = classes.successColor
      inputColor = classes.inputSuccess
      return { color, inputColor, badgeColor, tooltipColor }
    }

    if (!error) {
      return { color, inputColor, badgeColor, tooltipColor }
    }

    if (error.criticalType === errorTypes.critical) {
      color = classes.errorColor
      inputColor = classes.inputError
      tooltipColor = classes.tooltipError
    } else {
      color = classes.warningColor
      inputColor = classes.inputWarning
      tooltipColor = classes.tooltipWarning
    }

    return { color, inputColor, badgeColor, tooltipColor }
  }

  return [
    state,
    {
      cloneLine,
      splitLine,
      mergeLines,
      consolidateLines,
      deleteLines,
      addLine,
      editLine,
      setSelectedLines,
      setData,
      updatePagination,
      updatePoLines,
      editLines,
      updateAccount,
      validateAccountSegments,
      updateLines,
      getColors,
      classes,
      updateTaxesLine
    }
  ]
}
