import * as microsoftTeams from '@microsoft/teams-js'
import Auth from 'authentication/Auth'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { getHashParameters } from 'utils/functions'

import { loginInAuthService } from 'services/apiApps'
import { getOrganizationIdByTenant } from 'services/apiTenants'
import { loginUser, resetError, signOut } from 'store/AuthState'
import LoginView from './LoginView'

let loginOrigin

async function login(props) {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))

  // AuthService Login
  const loginResponse = await loginInAuthService(userInfo)

  if (loginResponse.success) {
    userInfo = loginResponse.data.userInfo

    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    localStorage.setItem('access_token', loginResponse.data.access_token)
    localStorage.setItem('id_token', loginResponse.data.access_token)

    props.loginUser(
      loginResponse.data.access_token,
      userInfo.name || userInfo.given_name || userInfo.family_name
    )

    const returnUrl = localStorage.getItem('returnUrl')
    if (returnUrl) {
      localStorage.removeItem('returnUrl')
      props.history.push(returnUrl)
    }
  } else {
    props.setErrorMessage(loginResponse.data)
    props.setError(true)

    const auth = new Auth(props.organizationId)
    // TO DO -- Check what's the best approach for this
    // auth.logout();
    auth.cleanSession()
  }
}

async function getOrganizationName(props) {
  const tenantIdIgnoreList = ['apps', 'apps-eu', 'localhost']
  //Get user tenantId
  const splittedDomain = window.location.hostname.split('.')
  const tenantId = splittedDomain[0]
  if (!tenantIdIgnoreList.includes(tenantId)) {
    const response = await getOrganizationIdByTenant(tenantId)

    if (response.success) {
      props.setOrganizationId(response.data.organizationId)
      props.setTenantId(tenantId)
    } else {
      props.setOrganizationId('')
    }
  }
}

function authenticate(props) {
  const auth = new Auth(props.organizationId)

  switch (props.loginOrigin) {
    case 'teams':
      microsoftTeams.authentication.authenticate({
        url: window.location.origin + '/auth/nitor-connect/login-start',
        successCallback: async function(result) {
          // console.info('Login succeeded: ' + JSON.stringify(result))
          props.changeIsLoadingLogin(true)
          await login(props)
          await getOrganizationName(props)
          props.changeIsLoadingLogin(false)
        },
        failureCallback: function(reason) {
          props.changeIsLoadingLogin(false)
          console.error('Login failed: ' + reason)
        }
      })
      break
    case 'ariba':
    case 'external':
      auth.popupLogin(
        { redirectUri: window.location.origin + '/auth/external/login-end' },
        async function(err, authResults) {
          if (err) {
            props.changeIsLoadingLogin(false)
            console.error('Login failed: ' + JSON.stringify(err))

            if (err.code && err.description) {
              props.setErrorMessage(`${err.code}: ${err.description}`)
              props.setError(true)
            }
          } else {
            // console.info('Login succeeded: ' + JSON.stringify(authResults))
            await auth.handlePopupAuthentication(authResults)
            await login(props)
            await getOrganizationName(props)
            props.changeIsLoadingLogin(false)
          }
        }
      )
      break

    default:
      auth.login()
      break
  }
}

function setReturnUrl(props) {
  // Return url validation
  if (props.location.state && props.location.state.from) {
    localStorage.setItem(
      'returnUrl',
      `${props.location.state.from.pathname}${props.location.state.from.search || ''}`
    )
  } else if (props.location.hash) {
    let hashParams = getHashParameters(props.location.hash)
    if (hashParams.returnUrl) {
      localStorage.setItem('returnUrl', hashParams.returnUrl)
    }
  } else {
    //Clear
    localStorage.removeItem('returnUrl')
  }
}

function getLoginOrigin(props) {
  // Get login origin
  loginOrigin = props.match.params.origin || props.loginOrigin
  props.changeLoginOrigin(loginOrigin)
  localStorage.setItem('login.method', loginOrigin)
}

async function checkFirstTime(props) {
  // first time
  props.changeIsLoadingLogin(true)
  let id_token = localStorage.getItem('id_token')
  if (!id_token) {
    const auth = new Auth(props.organizationId)
    try {
      await auth.handleAuthentication()
    } catch (error) {
      if (error.error) {
        props.setErrorMessage(`Invalid token: ${error.errorDescription}`)
        props.setError(true)
      }
    }
    if (auth.isAuthenticated()) {
      await login(props)
    }
    await getOrganizationName(props)
  }
  props.changeIsLoadingLogin(false)
}

export default compose(
  connect(
    state => ({
      isLoading: state.login.isLoading,
      isAuthenticated: state.login.isAuthenticated,
      error: state.login.error
    }),
    { loginUser, resetError, signOut }
  ),
  withRouter,
  withState('isLoadingLogin', 'changeIsLoadingLogin', true),
  withState('isUnauthorizedLogin', 'changeUnauthorizedLogin', false),
  withState('loginOrigin', 'changeLoginOrigin', 'web'),
  withState('organizationId', 'setOrganizationId', ''),
  withState('tenantId', 'setTenantId', ''),
  withState('errorMessage', 'setErrorMessage', ''),
  withState('error', 'setError', false),
  withHandlers({
    handleLoginButtonClick: props => () => {
      props.changeIsLoadingLogin(true)
      authenticate(props)
    },
    handleUnathorizedLogin: props => () => {
      props.changeUnauthorizedLogin(false)
      props.changeIsLoadingLogin(true)
      props.signOut()
    }
  }),
  lifecycle({
    componentDidMount() {
      setReturnUrl(this.props)
      microsoftTeams.initialize()
      getLoginOrigin(this.props)
      checkFirstTime(this.props)
    }
  })
)(LoginView)
