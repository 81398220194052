import React from "react";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid } from "@material-ui/core";

// material ui icons
import AddAlertIcon from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";

// images
import LoginImage from "assets/img/LoginImage.png";

import styles from "assets/jss/material-dashboard-pro-react/views/loginViewStyle.js";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h2 className={classes.cardTitle}>Relish</h2>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={12}>
                  <img width="100%" src={LoginImage} alt="logo" className={classes.logotypeImage} />
                </GridItem>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {props.isLoadingLogin ?
                  <Grid container className={classes.progressContainer}>
                    <div className={classes.circularProgress}>
                      <CircularProgress color="inherit" />
                    </div>
                  </Grid>
                  :
                  <Button color="primary" simple size="lg" block onClick={props.handleLoginButtonClick}>
                    Login
                </Button>
                }
                {
                  props.loginOrigin === "teams" ?
                    <p className={classes.right} style={{ color: "#2c786e" }}>
                      <NavLink
                                to={`/public/about`}>
                      {"New to Relish Apps?"}
                      </NavLink>
                    </p>
                    :
                    null
                }
              </CardFooter>
            </Card>
          </form>
          <Snackbar
              place="bl"
              color="danger"
              icon={AddAlertIcon}
              message={props.errorMessage}
              open={props.error}
              closeNotification={() => props.setError(false)}
              close
            />
        </GridItem>
      </GridContainer>
    </div>
  );
}
